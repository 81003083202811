import React from "react";
import { batch, connect } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { ApiService, ProjectDocumentService } from "../../api";
import {
	AnalyticsProvider,
	Flags,
	hasCompanyPermission,
	hasPermission,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
} from "../../common/providers";
import { exportView, NavUtils } from "../../navigation";
import {
	HorizontalDivider,
	SideDrawerMain,
	ViewBanner,
	withWebsocket,
	CustomButton,
	DocumentAutocompleteSearch,
	FiltersWrapper,
	CommonFilters,
	icon,
} from "../../common/components";
import {
	DirectoryLocation,
	DocumentCenterMenu,
	DocumentCenterMenuHeader,
	DocumentCenterTable,
	DocumentsStatusInformationBanner,
	SaveFileDialog,
	UploadDialog,
} from "./components";
import styles from "./DocumentCenter.module.css";
import { debounce, isAllowedFile } from "../../common/utils";
import {
	getContent,
	getCost,
	getLocation,
	getTree,
	ROOT_DIR,
	setHasActionPermission,
	setHasReceivedModifications,
	setLoadingLocation,
	setOpenUploadDialog,
	setSearchDocumentId,
	setUploadProgress,
	setPage,
	updateAnalysis,
	setImports,
	setImportProgress,
	getStatusCounters,
	setAnalyzingDocuments,
	setFailedDocuments,
	setHadDocumentsToAnalyze,
	setDocCenterFilters,
} from "./slice/document-center-slice";
import { onBeforeUnloadHandler } from "./utils/utils";
import PrecedenceSidePanel from "./components/precedence-side-panel/PrecedenceSidePanel";
import { DOCUMENT_STATUS } from "./constants/constants";

const debouncedFunction = debounce((func) => func(), 500);
const qualityAndProgress = [
	{ label: "document-center.filter.analysis-in-progress", value: { status: ["IN_PROGRESS"] } },
	{ label: "document-center.filter.analysis-failed", value: { status: ["FAILED", "TIMEOUT"] } },
	{
		label: "document-center.filter.qood-quality",
		value: { score: { min: 85, max: 100 }, status: ["COMPLETED"] },
		iconName: icon.faCircle,
		iconPlacement: "left",
		color: "var(--color-green)",
	},
	{
		label: "document-center.filter.average-quality",
		value: { score: { min: 30, max: 84 }, status: ["COMPLETED"] },
		iconName: icon.faCircle,
		iconPlacement: "left",
		color: "var(--color-orange)",
	},
	{
		label: "document-center.filter.insufficient-quality",
		value: { score: { min: 0, max: 29 }, status: ["COMPLETED"] },
		iconName: icon.faCircle,
		iconPlacement: "left",
		color: "var(--color-red)",
	},
];

const queryParam = "directory";

const mapStateToProps = ({ context, documentCenter }) => ({
	projectId: context.project.id,
	userId: context.user.id,
	currentDirectoryId: documentCenter.currentDirectoryId,
	isUploading: documentCenter.isUploading,
	page: documentCenter.page,
	limit: documentCenter.limit,
	sort: documentCenter.sort,
	docCenterFilters: documentCenter.docCenterFilters,
	direction: documentCenter.direction,
	hasActionPermission: documentCenter.hasActionPermission,
	hasReceivedModifications: documentCenter.hasReceivedModifications,
	openUploadDialog: documentCenter.openUploadDialog,
	imports: documentCenter.imports,
	analyzingDocuments: documentCenter.analyzingDocuments,
	failedDocuments: documentCenter.failedDocuments,
	hideDocStatusInfoBanner: documentCenter.hideDocStatusInfoBanner,
});

const mapDispatchToProps = (dispatch) => ({
	onSetImports: (files) => dispatch(setImports(files)),
	onGetLocation: (projectId, directoryId, token) => dispatch(getLocation({ projectId, directoryId, token })),
	onGetTree: (projectId, token) => dispatch(getTree({ projectId, token })),
	onGetContent: ({ projectId, directoryId, page, limit, sort, direction, token }) =>
		dispatch(getContent({ projectId, directoryId, page, limit, sort, direction, token })),
	onGetStatusCounters: ({ projectId, token }) => dispatch(getStatusCounters({ projectId, token })),
	onSetHasActionPermission: (hasPermissionToPerformAction) =>
		dispatch(setHasActionPermission(hasPermissionToPerformAction)),
	onSetHasReceivedModification: (hasReceivedModifications) =>
		dispatch(setHasReceivedModifications(hasReceivedModifications)),
	onUpdateAnalysis: (payload) => dispatch(updateAnalysis(payload)),
	onSetOpenUploadDialog: (openUploadDialog) => dispatch(setOpenUploadDialog(openUploadDialog)),
	onSetUploadProgress: (payload) => dispatch(setUploadProgress(payload)),
	onSetImportProgress: (payload) => dispatch(setImportProgress(payload)),
	onSetSearchDocumentId: (payload) => dispatch(setSearchDocumentId(payload)),
	onSetLoadingLocation: (payload) => dispatch(setLoadingLocation(payload)),
	onSetAnalyzingDocuments: (payload) => dispatch(setAnalyzingDocuments(payload)),
	onSetHadDocumentsToAnalyze: (payload) => dispatch(setHadDocumentsToAnalyze(payload)),
	onSetFailedDocuments: (payload) => dispatch(setFailedDocuments(payload)),
	onSetDocCenterFilters: (payload) => dispatch(setDocCenterFilters(payload)),
	onGetCost: (projectId, token) => dispatch(getCost({ projectId, token })),
	onSetPage: (payload) => dispatch(setPage(payload)),
});

class DocumentCenter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingDocuments: false,
			searchDocument: {},
			searchDocumentInputValue: "",
			openNewFolderDialog: false,
			openDrawer: true,
			openPrecedenceSidePanel: false,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
		this.menuButtonDropDownRef = React.createRef();
	}

	componentDidMount() {
		const { location, currentDirectoryId, onSetHasActionPermission, onGetCost, projectId } = this.props;
		const documentTitle = translate("document-center.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Documents" });
		const directoryId = NavUtils.getQueryParams(location).get(queryParam) ?? currentDirectoryId;
		this.handleRedirectToDirectory(directoryId);
		const hasActionPermission =
			hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) &&
			hasCompanyPermission([Permissions.PROJECT_LEADER]);
		onSetHasActionPermission(hasActionPermission);
		onGetCost(projectId, this.cancelTokenSource.token);
		this.subscribeSocket(hasActionPermission);
	}

	componentDidUpdate(prevProps) {
		const { location, hasReceivedModifications } = this.props;
		if (prevProps.location.search !== location.search) {
			const directoryId = NavUtils.getQueryParams(location).get(queryParam) || ROOT_DIR;
			this.handleRedirectToDirectory(directoryId);
		}
		if (prevProps !== hasReceivedModifications && hasReceivedModifications) {
			this.debouncedRefresh();
		}
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	subscribeSocket = (hasActionPermission) => {
		const { subscribeWS, projectId } = this.props;
		subscribeWS(`/topic/document-center/projects/${projectId}/refresh`, this.handleRefreshMessage);
		if (hasActionPermission) {
			subscribeWS(`/topic/document-center/projects/${projectId}/analysis`, this.handleAnalysisMessage);
		}
	};

	handleRefreshMessage = ({ userId, directoryId, reason }) => {
		const { userId: currentUserId, currentDirectoryId, onSetHasReceivedModification } = this.props;
		const originUser = +userId ? +userId : 0;
		const targetedDirectory = +directoryId ? +directoryId : ROOT_DIR;
		if (currentUserId === originUser && currentDirectoryId === targetedDirectory && reason !== "NEW_DOCUMENT") {
			return;
		}
		onSetHasReceivedModification(true);
	};

	handleAnalysisMessage = ({ id, name, type, status, progression, pages, parsingScore, reqs, versioningScore }) => {
		const {
			onUpdateAnalysis,
			onSetAnalyzingDocuments,
			onSetHadDocumentsToAnalyze,
			onSetFailedDocuments,
			analyzingDocuments,
			failedDocuments,
		} = this.props;
		if (analyzingDocuments > 0) {
			onSetHadDocumentsToAnalyze(true);
		}
		if (status === DOCUMENT_STATUS.COMPLETED && +progression) {
			onSetAnalyzingDocuments(analyzingDocuments - 1);
		}
		if (status === DOCUMENT_STATUS.FAILED && +progression) {
			onSetFailedDocuments(failedDocuments + 1);
		}
		onUpdateAnalysis({
			id: +id,
			name,
			type,
			status,
			progression: +progression,
			pages: +pages,
			parsingScore: status === DOCUMENT_STATUS.COMPLETED ? +parsingScore : null,
			reqs: +reqs,
			versioningScore: +versioningScore,
		});
	};

	handleRedirectToDirectory = (directoryId) => {
		const { navigate, projectId, onGetLocation } = this.props;
		onGetLocation(projectId, directoryId, this.cancelTokenSource.token)
			.then(unwrapResult)
			.catch(() => {
				const id = directoryId ?? ROOT_DIR;
				const url =
					id === ROOT_DIR
						? `/projects/${projectId}/documents`
						: `/projects/${projectId}/documents?${queryParam}=${id}`;
				navigate(url);
			});
	};

	debouncedSearch = debounce((name) => {
		const { projectId } = this.props;
		ProjectDocumentService.search({ projectId }, { name }, this.cancelTokenSource.token)
			.then((data) => this.setState({ searchDocument: data }))
			.catch(console.error)
			.finally(() => this.setState({ isLoadingDocuments: false }));
	}, 300);

	debouncedRefresh = debounce(() => {
		const {
			projectId,
			currentDirectoryId,
			onGetLocation,
			onGetCost,
			onSetHasReceivedModification,
			onGetTree,
			onGetContent,
			onGetStatusCounters,
			page,
			limit,
			sort,
			direction,
		} = this.props;
		const { token } = this.cancelTokenSource;
		debouncedFunction(() => {
			onGetStatusCounters({ projectId, token });
		});
		onGetLocation(projectId, currentDirectoryId, token);
		onGetTree(projectId, token);
		onGetContent({
			projectId,
			directoryId: currentDirectoryId,
			page,
			limit,
			sort,
			direction,
			token: this.cancelTokenSource.token,
		});
		onSetHasReceivedModification(false);
		onGetCost(projectId, this.cancelTokenSource.token);
	}, 1000);

	handleResetSearchInput = () => {
		this.setState({ isLoadingDocuments: false, searchDocument: [], searchDocumentInputValue: "" });
		this.handleResetSearchDocumentId();
	};

	handleResetSearchDocumentId = () => {
		const { onSetSearchDocumentId } = this.props;
		onSetSearchDocumentId(null);
	};

	handleSearchDocument = (name) => {
		const { onSetPage } = this.props;
		if (!name) {
			this.setState({ isLoadingDocuments: false, searchDocument: [], searchDocumentInputValue: name });
			return;
		}
		onSetPage(0);
		this.setState({ isLoadingDocuments: true, searchDocumentInputValue: name });
		this.debouncedSearch(name);
	};

	handleNavigateToFolder = ({ id: directoryId }, documentId = null) => {
		const { projectId, navigate, onSetSearchDocumentId, onSetLoadingLocation } = this.props;
		const id = directoryId ?? ROOT_DIR;
		const url =
			id === ROOT_DIR
				? `/projects/${projectId}/documents`
				: `/projects/${projectId}/documents?${queryParam}=${id}`;
		navigate(url);
		batch(() => {
			onSetLoadingLocation(true);
			onSetSearchDocumentId(+documentId || null);
		});
		this.setState({ isLoadingDocuments: false, searchDocument: [], searchDocumentInputValue: "" });
	};

	handleOpenNewFolderDialog = () => {
		if (this.menuButtonDropDownRef.current) {
			this.menuButtonDropDownRef.current.onClose();
		}
		this.setState((prev) => ({ openNewFolderDialog: !prev.openNewFolderDialog }));
	};

	handleImportFile = (targetFiles) => {
		const {
			projectId,
			currentDirectoryId,
			onSetOpenUploadDialog,
			onSetUploadProgress,
			onSetImports,
			imports,
			onSetHadDocumentsToAnalyze,
			onSetImportProgress,
			onSetHasReceivedModification,
		} = this.props;
		if (this.menuButtonDropDownRef.current) {
			this.menuButtonDropDownRef.current.onClose();
		}
		const files = Object.keys(targetFiles).map((key) => targetFiles[key]);
		const payload = {
			directoryId: currentDirectoryId === ROOT_DIR ? null : currentDirectoryId,
		};
		window.addEventListener("beforeunload", onBeforeUnloadHandler);
		const batchId = imports.length;
		onSetImports(
			files.map(({ lastModified, name, size, type }) => ({
				name,
				type,
				lastModified,
				size,
			}))
		);
		const startTime = new Date();
		if (files.some((file) => isAllowedFile(file.type))) {
			onSetHadDocumentsToAnalyze(false);
		}
		ProjectDocumentService.uploadDocuments(
			{ projectId },
			files,
			payload,
			(metadata) => {
				const { loaded, total } = metadata;
				const timeElapsed = new Date() - startTime;
				const uploadSpeed = loaded / (timeElapsed / 1000);
				const progression = (loaded / total) * 100;
				onSetImportProgress({ progression, batchId, uploadSpeed, total });
				onSetUploadProgress(progression);
			},
			this.cancelTokenSource.token
		)
			.then(() => {
				onSetHasReceivedModification(true);
			})
			.catch((err) => {
				console.error(err);
				onSetImportProgress({ progression: null, batchId, uploadSpeed: 0, total: 0 });
			})
			.finally(() => {
				window.removeEventListener("beforeunload", onBeforeUnloadHandler);
			});
		onSetOpenUploadDialog(true);
	};

	getDrawerComponent = () => <DocumentCenterMenu onClickDirectory={this.handleNavigateToFolder} />;

	getDrawerHeader = () => (
		<DocumentCenterMenuHeader
			onImportFile={this.handleImportFile}
			onOpenNewFolderDialog={this.handleOpenNewFolderDialog}
		/>
	);

	handleOpenDrawer = () => this.setState((prev) => ({ openDrawer: !prev.openDrawer }));

	handleOpenPrecedenceSidePanel = () =>
		this.setState((prev) => ({ openPrecedenceSidePanel: !prev.openPrecedenceSidePanel }));

	handleFilterChange = (newFilters) => {
		const { onSetDocCenterFilters } = this.props;
		onSetDocCenterFilters(newFilters);
	};

	render() {
		const {
			searchDocument,
			searchDocumentInputValue,
			isLoadingDocuments,
			openNewFolderDialog,
			openDrawer,
			openPrecedenceSidePanel,
		} = this.state;
		const {
			hasActionPermission,
			openUploadDialog,
			analyzingDocuments,
			failedDocuments,
			hideDocStatusInfoBanner,
			docCenterFilters,
		} = this.props;
		const precedenceButton = (
			<CustomButton variant="outlined" onClick={this.handleOpenPrecedenceSidePanel}>
				{translate("document-center.side-panel.manage-document-type")}
			</CustomButton>
		);
		return (
			<>
				<ViewBanner
					options={
						(hasPermission([Permissions.PROJECT_LEADER]) &&
							hasCompanyPermission([Permissions.PROJECT_LEADER]) &&
							isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER) &&
							isSegFeatureEnabled(SegFlags.PRECEDENCE) && [precedenceButton]) ||
						[]
					}
					titles={[
						{ title: translate("navigation:project.project-configuration"), key: "projectAdmin" },
						{ title: translate("navigation:home.documents") },
					]}
				/>
				<SideDrawerMain
					className={styles.sideDrawer__container}
					contentClassName={styles.sideDrawer__content}
					DrawerComponent={this.getDrawerComponent}
					DrawerHeader={this.getDrawerHeader}
					openDrawer={openDrawer}
					onOpenDrawer={this.handleOpenDrawer}
				>
					{((!hideDocStatusInfoBanner && !!failedDocuments) || !!analyzingDocuments) && (
						<DocumentsStatusInformationBanner />
					)}
					<div className={styles.main}>
						<div className={styles.main__search}>
							<span className={styles.search__autocomplete}>
								<DocumentAutocompleteSearch
									className={styles.autoComplete}
									documents={searchDocument?.contents || []}
									emptyStateLabel={translate("common:document-autocomplete-search.empty-state")}
									error={false}
									helperText={translate("document-center.search.helper")}
									inputValue={searchDocumentInputValue}
									isLoading={isLoadingDocuments}
									label={translate("document-center.search.title")}
									onReset={this.handleResetSearchDocumentId}
									onSearch={this.handleSearchDocument}
									onSelect={this.handleNavigateToFolder}
								/>
								<FiltersWrapper
									multiline
									components={[
										{
											default: true,
											enabled: true,
											labelKey: "label",
											valueKey: "value",
											component: CommonFilters.QUALITY_PROGRESS,
											dynamicItems: qualityAndProgress,
										},
									]}
									defaultFilters={docCenterFilters}
									onApply={this.handleFilterChange}
								/>
							</span>
						</div>
						<HorizontalDivider />
						<div className={styles.main__location}>
							<DirectoryLocation onClickDirectory={this.handleNavigateToFolder} />
						</div>
						<HorizontalDivider />
						<div className={styles.main__table}>
							<DocumentCenterTable
								docStatusInfoBannerDisplayed={
									(!hideDocStatusInfoBanner && !!failedDocuments) || !!analyzingDocuments
								}
								onImportFile={this.handleImportFile}
								onNavigateToFolder={this.handleNavigateToFolder}
							/>
						</div>
					</div>
				</SideDrawerMain>
				<PrecedenceSidePanel open={openPrecedenceSidePanel} onClose={this.handleOpenPrecedenceSidePanel} />
				<SaveFileDialog open={openNewFolderDialog} type="folder" onClose={this.handleOpenNewFolderDialog} />
				{hasActionPermission && <UploadDialog open={openUploadDialog} />}
			</>
		);
	}
}

export { default as documentCenterSlice } from "./slice/document-center-slice";
export default exportView({
	path: "/projects/:projectId/documents/*",
	component: withWebsocket(connect(mapStateToProps, mapDispatchToProps)(DocumentCenter)),
	localesPath: "/document-center/locales",
	flag: Flags.DOCUMENT_CENTER,
	segFlag: SegFlags.DOCUMENT_CENTER,
});
