import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DocumentService, ProjectDirectoryService, ProjectDocumentService } from "../../../api";
import { convertAnyDateToDisplayDate, isNonEmptyArray } from "../../../common/utils";
import { ContextProvider } from "../../../common/providers";

export const ROOT_DIR = "root";

export const getLocation = createAsyncThunk(
	"documentCenter/getLocation",
	({ projectId, directoryId = ROOT_DIR, token }) =>
		ProjectDirectoryService.getLocation({ projectId, directoryId }, token)
);

export const getContent = createAsyncThunk(
	"documentCenter/getContent",
	({
		projectId,
		directoryId = ROOT_DIR,
		sort = "",
		direction = "",
		searchDocumentId = null,
		filters = undefined,
		page = 0,
		limit = 15,
		token,
	}) => {
		if (!filters) {
			return ProjectDirectoryService.getDirectoryContent(
				{ projectId, directoryId },
				{ sort, direction, searchDocumentId, page, limit },
				token
			);
		}
		return ProjectDirectoryService.getRootContentFiltered(
			{ projectId },
			{ sort, direction, page, limit },
			{ filters },
			token
		);
	}
);
export const getStatusCounters = createAsyncThunk("documentCenter/getStatusCounters", ({ projectId, token }) =>
	ProjectDirectoryService.getStatusCounters({ projectId }, token)
);

export const getTree = createAsyncThunk("documentCenter/getTree", ({ projectId, token }) =>
	ProjectDirectoryService.getTree({ projectId }, token)
);

export const createFolder = createAsyncThunk(
	"documentCenter/createFolder",
	({ projectId, label, token }, { getState }) => {
		const { currentDirectoryId } = getState().documentCenter;
		const payload = { label, parentId: currentDirectoryId === ROOT_DIR ? null : currentDirectoryId };
		return ProjectDirectoryService.create({ projectId }, payload, token);
	}
);

export const renameFolder = createAsyncThunk("documentCenter/renameFolder", ({ projectId, directoryId, name, token }) =>
	ProjectDirectoryService.rename({ projectId, directoryId }, name, token)
);

export const renameFile = createAsyncThunk("documentCenter/renameFile", ({ docId, name, token }) =>
	DocumentService.renameDocument({ docId }, name, token)
);
export const changeDocType = createAsyncThunk("documentCenter/changeDocType", ({ projectId, docId, docType, token }) =>
	ProjectDocumentService.updateDocumentTypes({ projectId }, { docIds: [docId], type: docType }, token)
);

export const getCost = createAsyncThunk("documentCenter/getCost", ({ projectId, token }) =>
	ProjectDocumentService.getCost({ projectId }, token)
);

const initialState = {
	currentDirectoryId: ROOT_DIR,
	currentDirectoryContent: {},
	location: [],
	tree: { id: ROOT_DIR, parentId: null, label: null, directories: [] },
	isLoadingContent: false,
	isLoadingLocation: true,
	isLoadingTree: false,
	isLoadingUploads: false,
	isLoadingCost: false,
	docCenterFilters: {},
	sort: "FILENAME", // Possible values: PRECEDENCE, VERSION, UPDATED, PAGES, REQUIREMENT, FILENAME
	direction: "", // Possible values: ASC, DESC
	page: 0,
	limit: 50,
	searchDocumentId: null,
	cost: 0,
	uploadProgress: 0,
	isUploading: false,
	hasActionPermission: false,
	hasReceivedModifications: false,
	openUploadDialog: false,
	imports: [],
	importStats: [],
	analyzingDocuments: 0,
	hadDocumentsToAnalyze: false,
	failedDocuments: 0,
	hideDocStatusInfoBanner: ContextProvider.getHideDocStatusInfoBanner() || false,
};

const documentCenterSlice = createSlice({
	name: "documentCenter",
	initialState,
	reducers: {
		deleteImport: (state, { payload }) => {
			const { name, batchId } = payload;
			if (state.imports[batchId].length === 1) {
				return {
					...state,
					imports: state.imports.filter((_, index) => index !== batchId),
					importStats: state.importStats.filter((_, index) => index !== batchId),
				};
			}
			return {
				...state,
				imports: state.imports.map(
					(ip, index) => (index === batchId && ip.filter((upload) => upload.name !== name)) || ip
				),
			};
		},
		resetImports: (state) => ({ ...state, imports: [], importStats: [] }),
		setImportProgress: (state, { payload }) => ({
			...state,
			imports: state.imports.map((el, index) => {
				const { progression, batchId } = payload;
				if (index === batchId) {
					return [...el.map((e) => ({ ...e, progression }))];
				}
				return el;
			}),
			importStats: state.importStats.map((el, index) => {
				const { batchId } = payload;
				if (index === batchId) {
					return payload;
				}
				return el;
			}),
		}),
		setImports: (state, { payload }) => ({
			...state,
			imports: [...state.imports, payload],
			importStats: [...state.importStats, {}],
		}),
		setDirectoryContent: (state, { payload }) => ({ ...state, currentDirectoryContent: payload }),
		setSort: (state, { payload }) => ({ ...state, sort: payload.sort, direction: payload.direction }),
		setSearchDocumentId: (state, { payload }) => ({ ...state, searchDocumentId: payload || null }),
		setLoadingLocation: (state, { payload }) => ({ ...state, isLoadingLocation: payload }),
		setAnalyzingDocuments: (state, { payload }) => ({ ...state, analyzingDocuments: payload }),
		setHadDocumentsToAnalyze: (state, { payload }) => ({ ...state, hadDocumentsToAnalyze: payload }),
		setFailedDocuments: (state, { payload }) => ({ ...state, failedDocuments: payload }),
		setDocCenterFilters: (state, { payload }) => ({ ...state, docCenterFilters: payload }),
		setPage: (state, { payload }) => ({ ...state, page: payload }),
		setLimit: (state, { payload }) => ({ ...state, limit: payload }),
		setHideDocStatusInfoBanner: (state, { payload }) => {
			ContextProvider.setHideDocStatusInfoBanner(payload);
			return { ...state, hideDocStatusInfoBanner: payload };
		},
		setUploadProgress: (state, { payload }) => ({
			...state,
			uploadProgress: payload,
			isUploading: payload !== 100,
		}),
		setHasActionPermission: (state, { payload }) => ({ ...state, hasActionPermission: payload }),
		setHasReceivedModifications: (state, { payload }) => ({ ...state, hasReceivedModifications: payload }),
		setOpenUploadDialog: (state, { payload }) => ({ ...state, openUploadDialog: payload }),
		updateAnalysis: (state, { payload }) => {
			const { id, type, status, progression, pages, parsingScore, reqs, versioningScore } = payload;
			return {
				...state,
				currentDirectoryContent: {
					...state.currentDirectoryContent,
					contents:
						(isNonEmptyArray(state.currentDirectoryContent.contents) &&
							state.currentDirectoryContent.contents.map(
								(ct) =>
									(ct.id === id && {
										...ct,
										progression,
										status,
										type,
										pages,
										parsing_score: parsingScore,
										reqs,
										versioning_score: versioningScore,
									}) ||
									ct
							)) ||
						[],
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getLocation.fulfilled, (state, action) => {
			const { directoryId } = action.meta.arg;
			const currentDirectoryId = directoryId === ROOT_DIR ? ROOT_DIR : parseInt(directoryId, 10);
			return {
				...state,
				currentDirectoryId: Number.isNaN(currentDirectoryId) ? ROOT_DIR : currentDirectoryId,
				isLoadingLocation: false,
				location: [{ id: ROOT_DIR, label: null }, ...(action.payload || [])],
			};
		});
		builder.addCase(getLocation.pending, (state) => ({
			...state,
			isLoadingLocation: true,
		}));
		builder.addCase(getLocation.rejected, (state) => ({
			...state,
			currentDirectoryId: ROOT_DIR,
			currentDirectoryContent: {},
			isLoadingLocation: false,
			location: [{ id: 0, label: null }],
		}));
		builder.addCase(getContent.fulfilled, (state, action) => {
			const { sort, direction } = action.meta.arg;
			const page = action.payload.pageNumber;
			const { contents } = action.payload;
			return {
				...state,
				isLoadingContent: false,
				currentDirectoryContent: {
					...action.payload,
					contents,
				},
				page,
				sort,
				direction,
			};
		});
		builder.addCase(getContent.pending, (state) => ({
			...state,
			isLoadingContent: true,
		}));
		builder.addCase(getContent.rejected, (state) => ({
			...state,
			isLoadingContent: false,
			currentDirectoryContent: initialState.currentDirectoryContent,
		}));
		builder.addCase(getStatusCounters.fulfilled, (state, action) => {
			const { analyzingDocuments, failedDocuments } = action.payload;
			return { ...state, analyzingDocuments, failedDocuments };
		});
		builder.addCase(getTree.fulfilled, (state, action) => ({
			...state,
			isLoadingTree: false,
			tree: { ...action.payload, id: ROOT_DIR, label: null },
		}));
		builder.addCase(getTree.pending, (state) => ({
			...state,
			isLoadingTree: true,
		}));
		builder.addCase(getTree.rejected, (state) => ({
			...state,
			isLoadingTree: false,
			tree: initialState.tree,
		}));
		builder.addCase(createFolder.fulfilled, (state, action) => {
			const { id, label } = action.payload;
			const newContent = {
				type: "folder",
				id,
				label,
				filename: null,
				version: null,
				updated: convertAnyDateToDisplayDate(Date.now()),
				pages: null,
				reqs: null,
				validators: null,
				reviewers: null,
			};
			const contents = [newContent, ...state.currentDirectoryContent.contents];
			return {
				...state,
				currentDirectoryContent: { ...state.currentDirectoryContent, totalElements: contents.length, contents },
			};
		});
		builder.addCase(renameFolder.fulfilled, (state, action) => {
			const { id, label } = action.payload;
			const contents = [...state.currentDirectoryContent.contents];
			const index = contents.findIndex((content) => content.id === id && content.type === "folder");
			if (index < 0) {
				return { ...state };
			}
			const folder = { ...contents.find((content) => content.id === id && content.type === "folder") };
			folder.label = label;
			contents.splice(index, 1, folder);
			return {
				...state,
				currentDirectoryContent: { ...state.currentDirectoryContent, contents },
			};
		});
		builder.addCase(renameFile.fulfilled, (state, action) => {
			const { id, documentName } = action.payload;
			const contents = [...state.currentDirectoryContent.contents];
			const index = contents.findIndex((content) => content.id === id && content.type !== "folder");
			if (index < 0) {
				return { ...state };
			}
			const file = { ...contents.find((content) => content.id === id && content.type !== "folder") };
			file.label = documentName;
			contents.splice(index, 1, file);
			return {
				...state,
				currentDirectoryContent: { ...state.currentDirectoryContent, contents },
			};
		});
		builder.addCase(changeDocType.fulfilled, (state, action) => {
			const { docId, docType } = action.meta.arg;
			const contents = [...state.currentDirectoryContent.contents];
			const index = contents.findIndex((content) => content.id === docId && content.type !== "folder");
			if (index < 0) {
				return { ...state };
			}
			const file = { ...contents.find((content) => content.id === docId && content.type !== "folder") };
			file.docType = docType;
			contents.splice(index, 1, file);
			return {
				...state,
				currentDirectoryContent: { ...state.currentDirectoryContent, contents },
			};
		});
		builder.addCase(getCost.fulfilled, (state, action) => {
			const { payload } = action;
			return {
				...state,
				isLoadingCost: false,
				cost: payload,
			};
		});
		builder.addCase(getCost.pending, (state) => ({
			...state,
			isLoadingCost: true,
		}));
		builder.addCase(getCost.rejected, (state) => ({
			...state,
			cost: 0,
			isLoadingCost: false,
		}));
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export const {
	deleteImport,
	resetImports,
	setImportProgress,
	setImports,
	setDirectoryContent,
	setSort,
	setPage,
	setLimit,
	setAnalyzingDocuments,
	setHadDocumentsToAnalyze,
	setFailedDocuments,
	setHideDocStatusInfoBanner,
	setUploadProgress,
	setHasActionPermission,
	setHasReceivedModifications,
	setOpenUploadDialog,
	updateAnalysis,
	setSearchDocumentId,
	setLoadingLocation,
	setDocCenterFilters,
} = documentCenterSlice.actions;
export default documentCenterSlice.reducer;
